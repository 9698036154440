var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-layout-container"},[_c('div',{staticClass:"page-layout-main"},[_c('PageHeading',{attrs:{"breadcrumbs":_vm.breadcrumbs,"title":"Reports"}}),_c('FullPageLoadingSpinner',{attrs:{"show":_vm.$wait.is(_vm.$options.LoadingFlag.ChannelGroupReportGet)}}),_c('div',{staticClass:"m-b-2"},[_c('Input',{ref:"searchTerm",staticClass:"w-25",attrs:{"id":"search","type":"text","name":"search","label":"Search report","placeholder":"Search report","has-margin-bottom":false,"has-margin-top":true,"show-clear-icon":true,"default-icon":"magnifying-glass"},on:{"input":_vm.handleSearch},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1),_c('Datatable',{attrs:{"clickable-rows":true,"data":_vm.reportList,"columns":[
                {
                    value: 'name',
                    header: 'REPORT NAME',
                    type: 'text',
                    width: '25%',
                },
                {
                    value: 'selectedTags',
                    header: 'SELECTED TAGS',
                    type: 'text',
                    width: '40%',
                },
                {
                    value: 'createdAt',
                    header: 'CREATED ON',
                    type: 'date',
                    width: '15%',
                    format: _vm.config.dateFormat,
                },
                {
                    value: 'updatedAt',
                    header: 'UPDATED ON',
                    type: 'date',
                    width: '15%',
                    format: _vm.config.dateFormat,
                },
                {
                    value: 'actions',
                    class: 'table-row-actions',
                    header: '',
                    type: 'slot',
                    width: '5%',
                } ]},on:{"rowClick":_vm.handleRowclick},scopedSlots:_vm._u([{key:"actions",fn:function(ref){
            var item = ref.item;
return [_c('TableRowActions',{attrs:{"menu-actions":[
                        {
                            label: 'Update Data',
                            action: 'update',
                            icon: 'upload',
                        },
                        {
                            label: 'Delete Report',
                            action: 'delete',
                            icon: 'delete-full',
                        } ]},on:{"action":function($event){return _vm.handleUpdateDeleteReport(item, $event)}}})]}}])}),_c('Dialog',{attrs:{"show-confirm-button":"","confirm-button-text":_vm.action,"close-button-text":"Cancel","is-dialog-visible":_vm.dialogIsVisible},on:{"onClose":_vm.handleDialogCancel,"onClickOutside":_vm.handleDialogCancel,"closeOnEscapeEvent":_vm.handleDialogCancel,"confirmButtonOnClick":_vm.handleDialogConfirm},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(_vm._s(_vm.action.substring(0, _vm.action.length - 1) + 'ing'))]},proxy:true},{key:"body",fn:function(){return [_vm._v("Are you sure you want to "+_vm._s(_vm.action)+" this report?")]},proxy:true}])}),_c('Paging',{attrs:{"total":_vm.reportList.total,"size":_vm.reportList.size,"page":_vm.reportList.page},on:{"pageSelected":_vm.handlePageChange}}),_c('Stepper',{attrs:{"show-next-button":"","next-button-help":"","next-button-text":"New Report","is-loading":false},on:{"nextButtonOnClick":function($event){return _vm.$router.push({ name: 'create-report' })}}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }