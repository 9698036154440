<template>
    <div class="page-layout-container">
        <div class="page-layout-main">
            <PageHeading :breadcrumbs="breadcrumbs" title="Reports" />

            <FullPageLoadingSpinner :show="$wait.is($options.LoadingFlag.ChannelGroupReportGet)" />

            <div class="m-b-2">
                <Input
                    id="search"
                    ref="searchTerm"
                    v-model="searchTerm"
                    type="text"
                    name="search"
                    class="w-25"
                    label="Search report"
                    placeholder="Search report"
                    :has-margin-bottom="false"
                    :has-margin-top="true"
                    :show-clear-icon="true"
                    default-icon="magnifying-glass"
                    @input="handleSearch"
                />
            </div>

            <Datatable
                :clickable-rows="true"
                :data="reportList"
                :columns="[
                    {
                        value: 'name',
                        header: 'REPORT NAME',
                        type: 'text',
                        width: '25%',
                    },
                    {
                        value: 'selectedTags',
                        header: 'SELECTED TAGS',
                        type: 'text',
                        width: '40%',
                    },
                    {
                        value: 'createdAt',
                        header: 'CREATED ON',
                        type: 'date',
                        width: '15%',
                        format: config.dateFormat,
                    },
                    {
                        value: 'updatedAt',
                        header: 'UPDATED ON',
                        type: 'date',
                        width: '15%',
                        format: config.dateFormat,
                    },
                    {
                        value: 'actions',
                        class: 'table-row-actions',
                        header: '',
                        type: 'slot',
                        width: '5%',
                    },
                ]"
                @rowClick="handleRowclick"
            >
                <template v-slot:actions="{ item }">
                    <TableRowActions
                        :menu-actions="[
                            {
                                label: 'Update Data',
                                action: 'update',
                                icon: 'upload',
                            },
                            {
                                label: 'Delete Report',
                                action: 'delete',
                                icon: 'delete-full',
                            },
                        ]"
                        @action="handleUpdateDeleteReport(item, $event)"
                    />
                </template>
            </Datatable>

            <Dialog
                show-confirm-button
                :confirm-button-text="action"
                close-button-text="Cancel"
                :is-dialog-visible="dialogIsVisible"
                @onClose="handleDialogCancel"
                @onClickOutside="handleDialogCancel"
                @closeOnEscapeEvent="handleDialogCancel"
                @confirmButtonOnClick="handleDialogConfirm"
            >
                <template #header>{{ action.substring(0, action.length - 1) + 'ing' }}</template>
                <template #body>Are you sure you want to {{ action }} this report?</template>
            </Dialog>

            <Paging
                :total="reportList.total"
                :size="reportList.size"
                :page="reportList.page"
                @pageSelected="handlePageChange"
            />

            <Stepper
                show-next-button
                next-button-help=""
                next-button-text="New Report"
                :is-loading="false"
                @nextButtonOnClick="$router.push({ name: 'create-report' })"
            />
        </div>
    </div>
</template>

<script>
import FullPageLoadingSpinner from '@/components/ui/FullPageLoadingSpinner';
import { LoadingFlag } from '@/store/enums/loadingIds.enum.ts';
import { Breadcrumb } from '@/store/models/breadcrumb.dto.ts';
import TableRowActions from '@/components/TableRowActions';
import PageHeading from '@/components/ui/PageHeading';
import Datatable from '@/components/Datatable';
import Stepper from '@/components/ui/Stepper';
import { mapActions, mapState } from 'vuex';
import Input from '@/components/forms/Input';
import Paging from '@/components/Paging';
import config from '@/config';
import Dialog from '@/components/ui/Dialog';

export default {
    name: 'Reporting',
    components: {
        FullPageLoadingSpinner,
        TableRowActions,
        PageHeading,
        Datatable,
        Stepper,
        Paging,
        Input,
        Dialog,
    },
    data() {
        return {
            config,
            breadcrumbs: [],
            searchTerm: '',
            dialogIsVisible: false,
            action: '',
            selectedId: null,
        };
    },
    computed: {
        ...mapState('channelGroupReport', ['reportList']),
    },
    async created() {
        this.$options.LoadingFlag = LoadingFlag;
        this.setBreadcrumbs();
        this.getChannelGroupReports();
    },
    methods: {
        ...mapActions('channelGroupReport', [
            'getChannelGroupReports',
            'updateReportById',
            'deleteReport',
        ]),
        setBreadcrumbs() {
            this.breadcrumbs = [new Breadcrumb('Reports', { name: 'list-reports' })];
        },
        handlePageChange(event, page) {
            this.getChannelGroupReports({ page });
        },
        handleSearch() {
            this.getChannelGroupReports({
                q: this.searchTerm,
                page: this.reportList.page,
            });
        },
        handleRowclick(event, item) {
            if (item.id) {
                this.$router.push({ name: 'edit-report', params: { reportId: item.id } });
            }
        },
        showDialog() {
            this.dialogIsVisible = true;
        },
        hideDialog() {
            this.dialogIsVisible = false;
        },
        handleDialogCancel() {
            this.action = '';
            this.selectedId = null;
            this.hideDialog();
        },
        async handleDialogConfirm() {
            if (this.action == 'update') {
                await this.updateReportById(this.selectedId);
            }
            if (this.action == 'delete') {
                await this.deleteReport(this.selectedId);
            }
            this.getChannelGroupReports();
            this.hideDialog();
        },
        handleUpdateDeleteReport(item, action) {
            if (item.id) {
                this.action = action;
                this.selectedId = item.id;
                this.showDialog();
            }
        },
    },
};
</script>
